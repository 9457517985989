const translations = {
  fi: {
    niemikapeeFarm: 'Niemi-Kapee',

    cabin: {
      slug: 'mokit',
      title: 'Mökit',
    },
    sauna: {
      slug: 'saunat',
      title: 'Saunat',
    },
    events: {
      slug: 'tilaisuudet',
      title: 'Tilaisuudet',
    },
    activity: {
      slug: 'tekemista',
      title: 'Tekemistä',
    },
    price: {
      slug: 'hinnasto',
      title: 'Hinnasto',
    },
    contact: {
      slug: 'yhteystiedot',
      title: 'Yhteystiedot',
    },
    gift: {
      slug: 'lahjaksi',
      title: 'Lahjaksi',
    },
    catering: {
      slug: 'tarjoilut',
      title: 'Tarjoilut',
    },
    otherLang: {
      en: {
        title: 'In English',
      },
    },

    reservationForm: {
      reservation: "Varaaminen",
      goToReservation: "Siirry varaamaan",
      general: {
        message: 'Alustavan varauksen tekeminen',
        description1:
          '<p>Voit tehdä alustavan varauksen tarkistamalla vapaan ajankohdan ja täyttämällä alla olevan varauslomakkeen. Saat samalla alustavan hinnan varauksellesi.</p>',
        description2:
          '<p>Viikonloput vuokrataan pääsääntöisesti perjantaista sunnuntaihin.</p><p>Kesäaikana 1.6.-31.8. on mahdollista varata vain viikkoja ja viikkovaraukset ovat sunnuntaista sunnuntaihin.</p>',
        existingReservation: 'Olemassaoleva varaus',
        possibleReservation: 'Uusi varaus',
      },
      send: 'Lähetä alustava varauspyyntö',
      reservationSent: {
        message: 'Varauspyyntö lähetetty',
        description: 'Kiitos alustavasta varauksesta! Otamme sinuun yhteyttä!',
        reserveAgain: 'Tee toinen varaus',
      },
      reservationFailed: {
        message: 'Varauspyynnön lähettäminen epäonnistui',
        description:
          'Yritä uudestaan tai ota yhteyttä puhelimella tai sähköpostilla.',
      },
      dates: {
        title: 'Tulo- ja lähtöpäivä',
        errors: {
          required: 'Tulo- ja lähtöpäivä ovat pakollisia tietoja',
          startBeforeEnd: 'Tulopäivän tulee olla ennen lähtöpäivää.',
        },
      },
      startDate: {
        title: 'Tulopäivä',
        callToAction: 'Valitse tulopäivä',
        select: 'Valitse',
        dismiss: 'Peruuta',
        errors: {
          required: 'Tulopäivä on pakollinen tieto',
          startBeforeEnd: 'Tulopäivän tulee olla ennen lähtöpäivää.',
        },
      },
      endDate: {
        title: 'Lähtöpäivä',
        callToAction: 'Valitse lähtöpäivä',
        select: 'Valitse',
        dismiss: 'Peruuta',
        errors: {
          required: 'Lähtöpäivä on pakollinen tieto',
          startBeforeEnd: 'Lähtöpäivän tulee olla tulopäivän jälkeen.',
        },
      },
      numberOfPersons: {
        title: 'Henkilömäärä',
        errors: {
          required: 'Henkilömäärä on pakollinen tieto',
        },
      },
      price: {
        title: 'Hinta',
        placeholder: 'Syötä tulo- ja lähtöpäivä sekä henkilömäärä',
      },
      breakfast: {
        title: 'Aamupala',
        description: 'Lisää aamupala 10 €/hlö/päivä',
      },
      sauna: { title: 'Sauna', description: 'Lisää sauna 40 €/päivä ' },
      name: {
        title: 'Nimi',
        placeholder: 'Etunimi Sukunimi',
        errors: {
          required: 'Nimi on pakollinen tieto',
        },
      },
      email: {
        title: 'Sähköpostiosoite',
        placeholder: 'etunimi.sukunimi@example.com',
        errors: {
          required: 'Sähköpostiosoite on pakollinen tieto',
          validEmail: 'Anna oikea sähköpostiosoite',
        },
      },
      phone: {
        title: 'Puhelinnumero',
        placeholder: '+358 40 1234 567',
        errors: {
          required: 'Puhelinnumero on pakollinen tieto',
        },
      },
      recaptcha: {
        title: 'Varmistus',
        errors: {
          required: 'Ole hyvä ja tee varmistus loppuun.',
        },
      },
      pet: {
        title: 'Lemmikit',
        description: 'Matkustan lemmikin kanssa',
        instructions:
          'Lemmikit ovat sallittuja mökeillämme. Otathan kuitenkin huomioon kurakelit ja tassujen puhtauden. Loppusiivouksen yhteydessä käytä tarjottua karvarullaa.',
      },
      extra: {
        title: 'Lisätietoja',
        placeholder:
          'Lisätietoja tai toiveita lisäpalveluista kuten palju, erityissaunat tai muuta tekemistä loman ajaksi.',
      },
      months: [
        'Tammikuu',
        'Helmikuu',
        'Maaliskuu',
        'Huhtikuu',
        'Toukokuu',
        'Kesäkuu',
        'Heinäkuu',
        'Elokuu',
        'Syyskuu',
        'Lokakuu',
        'Marraskuu',
        'Joulukuu',
      ],
    },
    payment: {
      slug: "maksu",
      title: "Maksu"
    }
  },
  en: {
    niemikapeeFarm: 'Niemi-Kapee',

    cabin: {
      slug: 'cottages',
      title: 'Cottages',
    },
    sauna: {
      slug: 'saunas',
      title: 'Saunas',
    },
    events: {
      slug: 'events',
      title: 'Events',
    },
    activity: {
      slug: 'activities',
      title: 'Activities',
    },
    price: {
      slug: 'prices',
      title: 'Prices',
    },
    contact: {
      slug: 'contact',
      title: 'Contact',
    },
    gift: {
      slug: 'giftcards',
      title: 'Gift cards',
    },
    catering: {
      slug: 'catering',
      title: 'Catering',
    },
    otherLang: {
      fi: {
        title: 'Suomeksi',
      },
    },

    reservationForm: {
      reservation: "Reservation",
      goToReservation: "Go to reservation",
      general: {
        message: 'Making a preliminary booking',
        description1:
          '<p>Check available dates and fill the form for preliminary booking of cottage. You will also get a preliminary price for your booking.</p>',
        description2:
          '<p>During summer season 1.6.-31.8. it is possible to book only full weeks from Sunday to Sunday.</p><p>Weekends are mainly booked from Friday to Sunday.</p>',
        existingReservation: 'Existing reservation',
        possibleReservation: 'New reservation',
      },
      send: 'Send preliminary booking',
      reservationSent: {
        message: 'Booking sent',
        description:
          'Thank you for the preliminary booking. We will contact you!',
        reserveAgain: 'Make another reservation',
      },
      reservationFailed: {
        message: 'Sending booking failed',
        description: 'Try again or contact via phone or email..',
      },
      dates: {
        title: 'Check-in and check-out dates',
        errors: {
          required: 'Check-in and check-out dates are required',
          startBeforeEnd: 'Check-in date must be before check-out date.',
        },
      },
      startDate: {
        title: 'Check-in date',
        callToAction: 'Select check-in date',
        select: 'Select',
        dismiss: 'Cancel',
        errors: {
          required: 'Check-in date is required',
        },
      },
      endDate: {
        title: 'Check-out date',
        callToAction: 'Select check-out date',
        select: 'Select',
        dismiss: 'Cancel',
        errors: {
          required: 'Check-out date is required',
        },
      },
      numberOfPersons: {
        title: 'Number of persons',
        errors: {
          required: 'Number of persons is required',
        },
      },
      breakfast: {
        title: 'Breakfast',
        description: 'Incude breakfast 10 €/person/day ',
      },
      sauna: {
        title: 'Sauna',
        description: 'Incude sauna 40 €/day ',
      },
      price: {
        title: 'Price',
        placeholder:
          'Please input check-in and check-out dates and number of persons',
      },
      name: {
        title: 'Name',
        placeholder: 'John Doe',
        errors: {
          required: 'Name is required',
        },
      },
      email: {
        title: 'Email',
        placeholder: 'john.doe@example.com',
        errors: {
          required: 'Email is required',
          validEmail: 'Input valid email address',
        },
      },
      phone: {
        title: 'Phone number',
        placeholder: '+358 40 1234 567',
        errors: {
          required: 'Phone number is required',
        },
      },
      recaptcha: {
        title: 'Verification',
        errors: {
          required: 'Please fulfill the verification.',
        },
      },
      pet: {
        title: 'Pets',
        description: 'I am travelling with a pet',
        instructions:
          'Pets are allowed at our cabins. However please consider the weather and cleanliness of the paws. Use the provided hair remover at the end of your stay.',
      },
      extra: {
        title: 'Additional information',
        placeholder:
          'Additional information or requests for additional services like outdoor hot tub, special saunas or other activities during your stay.',
      },
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    },
    payment: {
      slug: "payment",
      title: "Payment"
    }
  },
}

module.exports = translations
