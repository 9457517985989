import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useStaticQuery, graphql } from "gatsby"

import './header.css'

import i18n from '../i18n/i18n'

const Header = ({ path }) => {
  const [isNavigationOpen, setNavigationOpen] = useState(false);

  const openNavigation = () => {
    setNavigationOpen(!isNavigationOpen);
  }

  const navClasses = "header__navigation" +
  (isNavigationOpen ? " header__navigation--open" : "");

  const lang = /^\/fi/.test(path) ? "fi" : "en";
  const otherLang = lang === "fi" ? "en" : "fi";

  const all = useStaticQuery(graphql`
    query cabinsForHeader {
      cabins: allPrismicCabin(sort: { fields: data___order, order: ASC }) {
        edges {
          node {
            uid
            lang
          }
        }
      },
      saunas: allPrismicSauna(sort: { fields: data___order, order: ASC }) {
        edges {
          node {
            uid
            lang
          }
        }
      },
      activities: allPrismicActivity(sort: { fields: data___order, order: ASC }) {
        edges {
          node {
            uid
            lang
          }
        }
      }
    }
  `)

  const cabins = all.cabins.edges.map(i => i.node).filter(node => node.lang.startsWith(lang))
  const saunas = all.saunas.edges.map(i => i.node).filter(node => node.lang.startsWith(lang))
  const activities = all.activities.edges.map(i => i.node).filter(node => node.lang.startsWith(lang))

  return (
    <header className="header">
      <h1 className="header__title title"><Link to={`/${lang}`}>{i18n[lang].niemikapeeFarm}</Link></h1>

      <div className="header__mobile-nav" onClick={openNavigation} onKeyPress={openNavigation} role="button" tabIndex="0">☰</div>

      <nav className={navClasses} id="main-navigation">
        <Link to={`/${lang}/${i18n[lang].cabin.slug}/${cabins[0].uid}`}>{i18n[lang].cabin.title}</Link>
        <Link to={`/${lang}/${i18n[lang].sauna.slug}/${saunas[0].uid}`}>{i18n[lang].sauna.title}</Link>
        <Link to={`/${lang}/${i18n[lang].events.slug}`}>{i18n[lang].events.title}</Link>
        <Link to={`/${lang}/${i18n[lang].catering.slug}`}>{i18n[lang].catering.title}</Link>
        <Link to={`/${lang}/${i18n[lang].activity.slug}/${activities[0].uid}`}>{i18n[lang].activity.title}</Link>
        <Link to={`/${lang}/${i18n[lang].gift.slug}`}>{i18n[lang].gift.title}</Link>
        <Link to={`/${lang}/${i18n[lang].price.slug}`}>{i18n[lang].price.title}</Link>
        <Link to={`/${lang}/${i18n[lang].contact.slug}`}>{i18n[lang].contact.title}</Link>
        <Link to={`/${otherLang}`}>{i18n[lang].otherLang[otherLang].title}</Link>
      </nav>
    </header>
  )
}

Header.propTypes = {
  path: PropTypes.string
}

Header.defaultProps = {
  path: '/'
}

export default Header
