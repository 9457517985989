import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import i18n from '../i18n/i18n'

import './footer.css'
import facebookLogo from '../images/facebook.png'
import visitTampereLogo from '../images/visit-tampere.png'

const Footer = ({ path }) => {
  useEffect(() => {
    const script = document.createElement('script')

    script.src =
      'https://www.jscache.com/wejs?wtype=cdsscrollingravenarrow&uniq=359&locationId=15553611&lang=fi&border=true&display_version=2'
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const lang = /^\/fi/.test(path) ? 'fi' : 'en'

  const all = useStaticQuery(graphql`
    query cabinsForFooter {
      frontpages: allPrismicFrontpage {
        edges {
          node {
            lang
            uid
            data {
              contact {
                details {
                  html
                }
                title {
                  text
                }
              }
            }
          }
        }
      }
      cabins: allPrismicCabin(sort: { fields: data___order, order: ASC }) {
        edges {
          node {
            uid
            lang
            data {
              name {
                text
              }
            }
          }
        }
      }
      saunas: allPrismicSauna(sort: { fields: data___order, order: ASC }) {
        edges {
          node {
            uid
            lang
            data {
              name {
                text
              }
            }
          }
        }
      }
      activities: allPrismicActivity(
        sort: { fields: data___order, order: ASC }
      ) {
        edges {
          node {
            uid
            lang
            data {
              name {
                text
              }
            }
          }
        }
      }
    }
  `)

  const frontpage = all.frontpages.edges
    .map(i => i.node)
    .filter(node => node.lang.startsWith(lang))[0]
  const cabins = all.cabins.edges
    .map(i => i.node)
    .filter(node => node.lang.startsWith(lang))
  const saunas = all.saunas.edges
    .map(i => i.node)
    .filter(node => node.lang.startsWith(lang))
  const activities = all.activities.edges
    .map(i => i.node)
    .filter(node => node.lang.startsWith(lang))

  return (
    <footer className="footer">
      <div>
        {frontpage.data.contact.map(contact => {
          const titleLastSpace = contact.title.text.lastIndexOf(" ")
          const titleStart = contact.title.text.substring(0, titleLastSpace >= 0 ? titleLastSpace : contact.title.text.length)
          const titleEnd = contact.title.text.substring(titleLastSpace >= 0 ? titleLastSpace : contact.title.text.length - 1, contact.title.text.length)

          return (
            <div className="footer__contact">
              <h1 className="title">
                {titleStart} <span className="title__highlight">{titleEnd}</span>
              </h1>
              <div className="footer__contact-info" dangerouslySetInnerHTML={{ __html: contact.details.html }} />
            </div>)
        })}
      </div>

      <nav className="footer__navigation">
        <ul>
          <li>
            <Link to={`/${lang}/${i18n[lang].cabin.slug}/${cabins[0].uid}`}>
              {i18n[lang].cabin.title}
            </Link>
            <ul>
              {cabins.map(cabin => (
                <li key={`footer-cabin-link-${cabin.uid}`}>
                  <Link to={`/${lang}/${i18n[lang].cabin.slug}/${cabin.uid}`}>
                    {cabin.data.name.text}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
          <li>
            <Link to={`/${lang}/${i18n[lang].sauna.slug}/${saunas[0].uid}`}>
              {i18n[lang].sauna.title}
            </Link>
            <ul>
              {saunas.map(sauna => (
                <li key={`footer-sauna-link-${sauna.uid}`}>
                  <Link to={`/${lang}/${i18n[lang].sauna.slug}/${sauna.uid}`}>
                    {sauna.data.name.text}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
          <li>
            <Link
              to={`/${lang}/${i18n[lang].activity.slug}/${activities[0].uid}`}
            >
              {i18n[lang].activity.title}
            </Link>
            <ul>
              {activities.map(activity => (
                <li key={`footer-activity-link-${activity.uid}`}>
                  <Link to={`/${lang}/${i18n[lang].activity.slug}/${activity.uid}`}>
                    {activity.data.name.text}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
          <li>
            <Link to={`/${lang}/${i18n[lang].events.slug}`}>
              {i18n[lang].events.title}
            </Link>
          </li>
          <li>
            <Link to={`/${lang}/${i18n[lang].catering.slug}`}>
              {i18n[lang].catering.title}
            </Link>
          </li>
          <li>
            <Link to={`/${lang}/${i18n[lang].gift.slug}`}>
              {i18n[lang].gift.title}
            </Link>
          </li>
          <li>
            <Link to={`/${lang}/${i18n[lang].price.slug}`}>
              {i18n[lang].price.title}
            </Link>
          </li>
          <li>
            <Link to={`/${lang}/${i18n[lang].contact.slug}`}>
              {i18n[lang].contact.title}
            </Link>
          </li>
          <li>
            <a
              href="https://www.facebook.com/pages/Niemi-Kapeen-tila/176567892235"
              target="_blank"
              rel="noopener noreferrer"
              id="facebook"
            >
              <img src={facebookLogo} alt="Facebook" />
            </a>
            <div
              className="fb-like"
              data-href="https://www.facebook.com/pages/Niemi-Kapeen-tila/176567892235"
              data-send="false"
              data-layout="box_count"
              data-width="50"
              data-show-faces="false"
              data-font="arial"
            ></div>
          </li>
        </ul>
      </nav>
      <a
        href="https://visittampere.fi"
        target="_blank"
        rel="noopener noreferrer"
        className="visit-tampere"
      >
        <img src={visitTampereLogo} alt="Visit Tampere" />
      </a>
    </footer>
  )
}

Footer.propTypes = {
  path: PropTypes.string,
}

Footer.defaultProps = {
  path: '/',
}

export default Footer
