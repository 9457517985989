
import { Location } from '@reach/router'
import 'antd/dist/antd.css'
import PropTypes from 'prop-types'
import React from 'react'
import '../styles/common.css'
import '../styles/fonts.css'
import '../styles/normalize.css'
import Footer from './footer'
import Header from './header'
import './layout.css'



const Layout = ({ children, page }) => (
  <Location>
    {({ location }) => {
      return (
        <section className={`wrapper page-${page}`}>
          <Header path={location.pathname} />
          <div className="content">
            {children}
          </div>
          <Footer path={location.pathname} />
        </section>
      )
    }}
  </Location>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
